import React from "react";
import {
    ConnectProvider,
    OKXConnector,
    UnisatConnector,
    XverseConnector,
} from '@particle-network/btc-connectkit';
import {Bitlayer, Core} from '@particle-network/chains'

interface Props {
    children: React.ReactNode;
}

const ParticleNetworkConnectProvider: React.FC<Props> = ({children}) => {
    return <ConnectProvider
        connectors={[
            new UnisatConnector(),
            new OKXConnector(),
            new XverseConnector()
        ]}
        options={{
            projectId: process.env.REACT_APP_PARTICLE_NETWORK_PROJECT_ID ?? '',
            clientKey: process.env.REACT_APP_PARTICLE_NETWORK_CLIENT_ID ?? '',
            appId: process.env.REACT_APP_PARTICLE_NETWORK_APP_ID ?? '',
            aaOptions: {
                accountContracts: {
                    BTC: [
                        {
                            chainIds: [Bitlayer.id],
                            version: '2.0.0',
                        },
                        {
                            chainIds: [Core.id],
                            version: '2.0.0'
                        }
                    ],
                },
            },
            walletOptions: {
                visible: true,
            },
        }}
    >
        {children}
    </ConnectProvider>
}

export default ParticleNetworkConnectProvider;